
	import { PropType } from 'vue';
	import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
	import { namespace } from "vuex-class";
	import { GetAddressMixin } from '@/mixins/get-address-mixin.component';
	import { GetContactName } from '@/mixins/get-contact-name.component';
	import { Contact } from '@/modules/contacts/types/entities';

	@Component({
		mixins: [GetAddressMixin, GetContactName]
	})
	export default class ContactsListDenseComponent extends Vue {
		@Prop({
			type: Array as PropType<Contact[]>, default: () => []
		})
		contacts!: Contact[];

		@PropSync('selectedContactId', {
			type: Number, default: -1
		})
		selectedContactIdSync!: Number;
	}
