
	import { Component, Emit, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
	import _ from 'lodash';

	import { TeleinformationDetail } from '@/modules/entities/types/entities';
	import { Actions, Getters } from '@/modules/contacts/store/types/StoreTypes';
	import ContactsListDenseComponent from '@/modules/contacts/components/contacts-list-dense/contacts-list-dense.component.vue';
	import { AxiosError, AxiosResponse } from 'axios';
	import { JsonResource, Pagination } from '@/core/types/Entities';
	import { validationMixin } from 'vuelidate';
	import { Contact, ContactVM } from '@/modules/contacts/types/entities';

	const contacts = namespace('contacts');

  const isLastNameRequiredValidator = (value: string, vm: CreateContactComponent): boolean => {
    if (vm.selectedExistingContactId > 0) {
      return true;
    }
    return value.length > 0;
  };
	const isFirstNameRequiredValidator = (value: string, vm: CreateContactComponent): boolean => {
		if (vm.selectedExistingContactId > 0) {
			return true;
		}
		return value.length > 0;
	};
	const areTeleinformationsRequiredValidator = (value: TeleinformationDetail[], vm: CreateContactComponent): boolean => {
		if (vm.selectedExistingContactId > 0) {
			return true;
		}
		return value.some(x => (x.landlinePhone || x.mobilePhone || x.email));
	};

	@Component({
		mixins: [validationMixin],
		components: {
			'contacts-list-dense': ContactsListDenseComponent
		},
		validations: {
			contact: {
				firstName: { isFirstNameRequired: isFirstNameRequiredValidator },
				teleinformations: {
					areTeleinformationsRequired: areTeleinformationsRequiredValidator,
				},
        lastName: { isLastNameRequired: isLastNameRequiredValidator }
			}
		}
	})
	export default class CreateContactComponent extends Vue {
		@PropSync('showModal', { type: Boolean, default: false }) showModalSync!: boolean;

		@contacts.Action(Actions.CREATE_NEW_CONTACT) createNewContact!: (payload: Contact) => Promise<AxiosResponse<JsonResource<Contact>>>;
		@contacts.Action(Actions.CHECK_FOR_EXISTING_CONTACT) checkForExistingContact!: (payload: Contact) => Promise<AxiosResponse<Pagination<Contact[]>>>;
		@contacts.Action(Actions.FETCH_CONTACTS_BY_NAME) fetchContactsByName!: (payload: string) => Promise<AxiosResponse<Pagination<Contact[]>>>;

		@contacts.Getter(Getters.GET_LOADING) isLoading!: boolean;

		contact = new ContactVM({
			teleinformations: [
				{
					id: 0,
					landlinePhone: '',
					landlinePhoneComment: '',
					mobilePhone: '',
					mobilePhoneComment: '',
					email: '',
					emailComment: '',
					isMainContactInformation: true,
					user: undefined,
					updatedAt: ''
				}
			]
		});

		existingContacts: Contact[] = [];
		fetchedContacts: Contact[] = [];
		checkedForExistingContacts = false;
		selectedExistingContactId = -1;
		contactsAutocompleteVisible = false;
		validationErrors: any = null;

		get nameErrors(): string[] {
			const errors: string[] = [];
			!this.$v.contact.firstName!.isFirstNameRequired && errors.push('Naam is vereist');
			!this.$v.contact.lastName!.isLastNameRequired && errors.push('Familienaam is vereist')
			return errors;
		}

		get teleinformationErrors(): string[] {
			const errors: string[] = [];
			!this.$v.contact.teleinformations!.areTeleinformationsRequired && errors.push('Minstens 1 contactmethode is vereist');
			return errors;
		}

		toggleAutocompleteVisibility(): void {
			this.contactsAutocompleteVisible = !this.contactsAutocompleteVisible;
			this.scrollToBottom();
		}

		onAddTeleinformationDetails(): void {
			this.contact.teleinformations.push({
				id: 0,
				landlinePhone: '',
				landlinePhoneComment: '',
				mobilePhone: '',
				mobilePhoneComment: '',
				email: '',
				emailComment: '',
				isMainContactInformation: false,
				user: undefined,
				updatedAt: ''
			});
		}

		onCreateContactFormSubmit(): void {
			const result = JSON.parse(JSON.stringify(this.contact));
			result.teleinformations = result.teleinformations.filter((x: TeleinformationDetail) => x.landlinePhone || x.landlinePhoneComment || x.mobilePhone || x.mobilePhoneComment || x.email || x.emailComment);
			if (this.selectedExistingContactId > 0) {
				this.emitOnAttachContact(this.selectedExistingContactId);
			} else {
              this.checkForContacts(result).then(() => {

                if((this.checkedForExistingContacts && (!this.selectedExistingContactId || this.selectedExistingContactId < 0)) || (this.existingContacts && this.existingContacts.length === 0)) {
                  this.createNewContact(result)
                    .then((res: AxiosResponse<JsonResource<Contact>>) => {
                      this.emitOnAttachContact(res.data.data.id);
                    }).catch((err: AxiosError) => {
                      if (err.response && err.response.status === 422) {
                        this.validationErrors = err.response.data.errors;
                      } else {
                        throw err;
                      }
                    });
                }
                this.checkedForExistingContacts = true;
              });
            }
		}

		onInputChange = _.debounce((value: string) => {
			console.log(value)
			if (value && !value.startsWith(' ')) {
				this.fetchContacts(value);
			}
		}, 200);

		scrollToBottom(): void {
			this.$nextTick(() => {
				(this.$refs.modalContent as HTMLElement).scrollTop = (this.$refs.modalContent as HTMLElement).scrollHeight;
			});
		}

		checkForContacts(contact: Contact) {
			return this.checkForExistingContact(contact).then((res: AxiosResponse<Pagination<Contact[]>>) => {
				if (res.data.data.length) {
					this.existingContacts = res.data.data;
				}
			});
		}

		fetchContacts(value: string): void {
			this.fetchContactsByName(value).then((result: AxiosResponse<Pagination<Contact[]>>) => {
				this.fetchedContacts = result.data.data;
			});
		}

		@Emit('onAttachContact')
		emitOnAttachContact(contactId: number): number {
			return contactId;
		}

		@Watch('existingContacts')
		onExistingContactsChange(): void {
			this.scrollToBottom();
		}

		@Watch('fetchedContacts')
		onFetchedContactsChange(): void {
			this.scrollToBottom();
		}

		@Watch('contact.firstName')
		onContactNameChange(): void {
			if (this.validationErrors && this.validationErrors.firstName) {
				this.validationErrors.firstName = null;
			}
		}

		@Watch('contact.teleinformations', { deep: true })
		onContactTeleinformationsChange(): void {
			if (this.validationErrors && this.validationErrors.teleinformations) {
				this.validationErrors.teleinformations = null;
			}
		}

	}
